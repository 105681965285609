import React from 'react';
import useForm from 'react-hook-form';
import Axios from 'axios';
import { navigate } from 'gatsby';
import FormButton from '../components/buttons/form-button';
import SEO from '../components/seo';
import { Route } from '../models/site-links';
import './form.css';

const WorkWithUsForm = () => {
  const { register, handleSubmit } = useForm();
  const submitForm = (data: any) => {
    const formData = {
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone,
    };
    Axios.post(
      'https://prod-25.canadacentral.logic.azure.com:443/workflows/bd082559dd4640869eef8357d8746352/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=7uu0tkkcjNWXRAuBYtUKzWom7BF0WcDKU_PXnrSm4J8',
      formData,
    ).then(() => navigate(Route.JobThanks));
  };
  return (
    <div>
      <SEO title="Work With Us - Patronscan" />
      <div className="flex flex-col min-h-screen justify-center mx-auto max-w-container pt-24">
        <div className="p-4 md:w-4/5 mx-auto">
          <div className="mb-10 mt-8">
            <h2 className="mb-4 text-blue font-heading leading-none text-5xl thick-heading">
              Work With Us
            </h2>
            <p className="leading-tight text-grey form-text-base">
              Thank you for your interest in Patronscan. Please fill out the form below.
            </p>
          </div>
          <form onSubmit={handleSubmit(submitForm)}>
            <div className="inline-flex flex-wrap w-full mb-8">
              <label className="block w-full form-text-base mb-2" htmlFor="email">
                Email
              </label>
              <input
                className="p-2 border-blue rounded-lg border block w-full form-text-base"
                type="email"
                id="email"
                name="email"
                ref={register({ required: true })}
              />
            </div>
            <div className="inline-flex flex-wrap w-full md:w-1/2 mb-8">
              <label className="block w-full form-text-base mb-2" htmlFor="firstname">
                First Name
              </label>
              <input
                className="p-2 border-blue rounded-lg border block w-full form-text-base"
                type="text"
                id="firstname"
                name="firstname"
                ref={register({ required: true })}
              />
            </div>
            <div className="inline-flex flex-wrap w-full md:w-1/2 md:pl-4 mb-8">
              <label className="block w-full form-text-base mb-2" htmlFor="lastname">
                Last Name
              </label>
              <input
                className="p-2 border-blue rounded-lg border block w-full form-text-base"
                type="text"
                id="lastname"
                name="lastname"
                ref={register({ required: true })}
              />
            </div>
            <div className="inline-flex flex-wrap w-full mb-8">
              <label className="block w-full form-text-base mb-2" htmlFor="phone">
                Phone Number
              </label>
              <input
                className="p-2 border-blue rounded-lg border block w-full form-text-base"
                type="phone"
                id="phone"
                name="phone"
                ref={register({ required: true })}
              />
            </div>
            <div className="mb-20 form-submit-container">
              <FormButton
                formId="WorkWUsSubmit"
                className="form-submit-button"
                text="Submit"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WorkWithUsForm;
